import React from "react";

const Header = () => {
  return (
    <div className="header">
      <h1 className="title">With the Spread</h1>
      <h4 className="subtitle">Predicted outcomes Powered by data</h4>
      <h3 className="subtitle">
        Have a gambling problem? Call 1-800-GAMBLER for help.
      </h3>
    </div>
  );
};

export default Header;
